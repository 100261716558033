import React from 'react';
import OffersNav from '../components/Offers_Nav';
import Hamburger from "../components/Hamburger_Nav.js"
import { Link,useNavigate } from 'react-router-dom';
// import Intro_video from './Images/intro_video.mp4';
import { Modal } from 'antd';
import { useState,useEffect } from 'react';
import Resfooter from '../components/Resfooter.js';
import axios from 'axios';
import Footer from '../components/Footer.js';
import toast from 'react-hot-toast';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './Styles/PackageBooking_details.css';

import countries from '../components/Country_List.js';
import {viewAllPackages,BookingEnquiry} from '../Utilis/BaseUrl.js'
import Success from './Images/success.png'
import Logo from './Images/loaderLogo.png'
import Whatsapp from '../components/Whatsapp.js';
import slugify from 'slugify';
import skeletonImage from './Images/sheleton loader image.png'
import emptyImg from './Images/Globus.png'







function Packages(props) {

  const [visible, setVisible] = useState(false);
  const [ alert , setalert] = useState(false)
  const [loaderLoad , setloaderLoad] = useState(true)
  const [userData,setUserDate] =  useState([]);




  const [getallPackages,setallAPackage] = useState([])
  const ListAllPackage = async ()=>{
    try{

        const response = await axios.get(`${viewAllPackages}`);
        if(response){
            setallAPackage(response.data.data); 
            setloaderLoad(false)                              
        }
    }catch(error){
        // console.log(error)
        navigate('/404')

    }
  }


  //view specific packages 
  const navigate = useNavigate();
  const handlepackage =(id , package_name)=>{
    sessionStorage.setItem('Package_id',id);
    const slug = slugify(package_name)
    navigate (`/packagebooking/${slug}/${id}`)

  }

  useEffect(()=>{
    ListAllPackage();
    FOnPageLoad();
  },[]);


  function FOnPageLoad(){ 
    const sessionUserData =localStorage.getItem('myDate');
  if(sessionUserData)
    {
        setUserDate(JSON.parse(sessionUserData))

    }
  }


  


  

//customize package
const [user_name,setuser_name] = useState()
const [user_email,setuser_email] = useState()
const [user_nationality,setuser_nationality] = useState()
const [user_number,setuser_number] = useState()
const [description,setdescription] = useState()


useEffect(()=>{
  setuser_email(userData.email )
  setuser_name(userData.name)
},[userData])

const HandleSubmit = async(e) =>{
  e.preventDefault();  
  try {
   if(user_name,user_email,user_nationality,user_number){
    const res = await axios.post(`${BookingEnquiry}`,{user_name,user_email,user_nationality,user_number,description});
 

      
      if(res?.data){
          // toast.success(res && res.data.msg)
          setVisible(false)
          setuser_name('');
          setuser_email('');
          setuser_nationality('');
          setuser_number();
          setdescription('');

          if(res?.data?.msg){
            setalert(true)

          }

      }
      else{
        
          // console.log(res.data.msg)

      }
    }else{
      toast.error('Please fill the fields')
}
  } catch (error) {
      // console.log(error)
      // toast.error('error in insertion')
      navigate('/404')
  }
}

//country 

const handleChange = (event) => {
  setuser_nationality(event.target.value);
};

//package image loader
const [packageImageLoad, setpackageImageLoad] = useState({});
           
  const handlepackageimage = (id) => {
    setpackageImageLoad((prevStates) => ({
       ...prevStates,
      [id]: false,
    }));
  };

  function clearmodalData(){
    setVisible(false)
    setuser_name('');
    setuser_email('');
    setuser_nationality('');
    setuser_number('');
    setdescription('')
  }

  return (
    <div>
      {/* loader------ */}
   {loaderLoad === true ? 
    <div className='flex items-center justify-center flex-col  h-[100vh]   '>
      <img className='h-[60px]' src={Logo} alt='' />
      <div className="dots mt-3"></div>
     </div>
     :
     <>
      
      <OffersNav />
      <Hamburger />
      <div className='body-container-row relative'>
        <div className='section-1 relative'>
          <video className='into-video border-[0] filter contrast-75' autoPlay muted loop  >
            <source src='https://d1ikk3qahjralr.cloudfront.net/trippers-media/intro_video.65f430fda65e06b10a34.mp4' type="video/mp4" />
          </video>
          <div className='serach_and-name mt-[-69px] z-10   '>
            <h1 >Packages for you</h1>
            <h6 className='pt-[0px] m-[0px]'>Embark on unforgettable journeys with trippers</h6>

          </div>


          <div className='h-[94px] w-[139px] bg-[#FD8062] absolute right-[0] rounded-l-[16px] z-10
                        flex-row items-center justify-center p-[8px] cursor-pointer
                        max-md:w-[120px] max-md:h-[89px] max-md:top-[40px]
                         max-sm:w-[110px] max-sm:h-[80px] max-sm:top-[20px]'
                        
            onClick={() => { setVisible(true);FOnPageLoad(); }} type='buttton'
          >
            <div className='flex items-center justify-center pt-1'   >
              <span className="material-symbols-outlined 
                               max-md:text-[20px]
                              "  >phone_in_talk</span>

            </div>

            <h6 className='font-[500] text-[14px] leading-[20px] text-center pt-2 
                           max-md:text-[12px]  max-md:leading-[18px]
                           max-sm:text-[10px]
                           max-sm:leading-[15px]'>Customize your Package</h6>
          </div>
          <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 pointer-events-none"></div>
        </div>

        {/* -----------------------section 2 ------------------------------------- */}
        <div className=" pt-10 mx-auto max-w-[1184px] h-auto mb-[80px] bg-[white]
           max-xl:w-[980px] 
           max-lg:w-[700px] 
          max-md:w-[550px]  
            max-sm:w-[100%] max-sm:px-[16px] max-sm:mt-[-25px]  ">
          <div className=" flex flex-row mt-4 gap-[2%] max-w-full h-auto">
     
            <div className="w-[100%]
            h-full ">
              {getallPackages.length>0 ?
                <>
                  <div className='text-2xl font-bold leading-27 text-left
                                max-md:text-[20px] 
                                max-sm:text-[18px] '>Discover Our Exclusive Packages</div>
                  <div id="main" className="grid gap-6 justify-evenly mt-6 grid-cols-4
                                            max-xl:grid-cols-4  
                                            max-lg:grid-cols-3  
                                            max-md:grid-cols-2    
                                            max-sm:grid-cols-1   " >

                  {getallPackages?.map((l)=> (
                  <>
                    {l?.is_active === true && 
                      <div className="activity-card cursor-pointer relative" key={l.id} onClick={()=>handlepackage(l.id,l.package_name)}> 
                        {packageImageLoad[l.id] !== false  && ( <img className="activity-card-img rounded-lg object-cover  animate-pulse" src={skeletonImage} alt='' /> )} 
                        <img className={`activity-card-img rounded-lg object-cover ${l.public_enabled === true ?'grayscale-0':'grayscale'}`} src={l.package_image[0]} alt='' onLoad={() => handlepackageimage(l.id)}
                        style={{ display: packageImageLoad[l.id] !== false ? 'none' : 'block' }}   /> 
                        <div className='activity-card-name text-[15px] leading-[27px] mt-2  h-[55px]  line-clamp-2
                                        max-lg:text-[15px] max-lg:leading-[25px] max-lg:h-[45px] 
                                        max-sm:h-[27px]  max-sm:line-clamp-1'>{l.package_name}
                        </div>
                        <div className=' section-2-row-2-card-1-days mt-2   rounded-3xl  py-[10px] text-white font-normal text-sm ' >
                        <h6 className='bg-[#000000] px-[12px] rounded-[24px] py-[6px] 
                                        max-lg:mt-3 max-lg:text-[10px] 
                                        max-sm:mt-1 '>{l?.package_duration_day} days &nbsp;{l?.package_duration_night}  night</h6>
                        </div>
                        <div className='activity-card-rate text-[16px] leading-[34px]
                                        max-lg:mt-2  max-lg:text-[13px]
                                        max-sm:mt-1'>{l.package_offer==='' || l.package_offer =='0' ?<span>$ {l.package_price}</span>:<>
                                        <span className='font-[400]'><del>$ {l.package_price}</del>&nbsp;&nbsp;<strong>$ {l.package_discount_price}</strong> </span>
                                                <div className={`w-[50px] h-[30px] ${l.public_enabled === true ? 'bg-[#f97316]' : 'bg-[#3490dc] grayscale'} transform -rotate-45 flex items-center justify-center text-white text-[16px] font-[700] absolute top-[13px] left-[5px]`}>
                                                {l.package_offer}% </div>
                                          </>
                                        }<span className='activity-card-span text-[14px] max:lg-text-[12px] '> per person</span></div>
                      </div>

                      }
                        </>
                    ))}


                    
                  </div>
                </>
              :<div className='flex items-center justify-center'> 
              <div className='flex flex-col justify-center items-center'>
                <img className='w-[120px]' src={emptyImg} alt='no package' />
                <h5 className='font-[500] mt-1 text-[grey]'>No package available</h5>
              </div>
              </div> }
            </div>
            

          </div>
        </div>
        {/* <Resfooter/> */}
        

        <Modal onCancel={() => (clearmodalData())}
          footer={null}
          visible={visible}
          width={'664px'}
          
        >
          <div className='h-[auto] mb-[85px] '>
            <h4 className='font-[700] text-[16px] leading-[19.5px] text-[#464646] '>Customize your package</h4>
            <form className='pt-4 '  onSubmit={HandleSubmit} method='' action=''>
              <div className="mb-3">
                <label  className="form-label font-[400] text-[12px] leading-[16px] text-[#32324D]">Your Name</label>
                <input type="text" className="form-control mt-2 h-[48px] p-[8px] bg-[#FAFAFA] border border-gray-300 outline-none focus:outline-none focus:ring-2 focus:ring-black" placeholder='Eg. John Doe' required
                 value={user_name} 
                 onChange={(e) => setuser_name(e.target.value)}/>

              </div>
              <div className="mb-3 mt-3">
                <label  className="form-label font-[400] text-[12px] leading-[16px] text-[#32324D]">Phone Number</label>
                {/* <input type="text" className="form-control mt-2 h-[48px] p-[8px] bg-[#FAFAFA]" placeholder='Eg. +91- 7894561230' 
                 value={user_number} 
                 onChange={(e) => setuser_number(e.target.value)}/> */}
                     <PhoneInput
                                                country={'us'}
                                                value={user_number}
                                                onChange={user_number => setuser_number(user_number)}
                                                inputStyle={{
                                                  width:'100%',
                                                  height:'48px',
                                                  borderColor:'#EAEAEA',
                                                  fontSize:'16px',
                                                  fontWeight:'10px',
                                                 outline:'none',
                                                 boxShadow:"none",
                                                 color:"#32324D",
                                                 backgroundColor:"#FAFAFA"
                                                
                                                 
                                               
                                                }}
                                                buttonStyle={{
                                                  position: 'absolute',
                                                  top: '50%',
                                                  transform: 'translateY(-50%)',
                                                  left: '1.5px',
                                                  border: '2px solid transparent',
                                                  outline: 'none',
                                                  cursor: 'pointer',
                                                  backgroundColor:'transparent'
                                              }}
                                                inputClass="red-border" // Custom class for red border on focus
                                                buttonClass="red-border"
                                              />
              </div>
              <div className="mb-3 mt-3">
                <label className="form-label font-[400] text-[12px] leading-[16px] text-[#32324D]">Your Email-id</label>
                <input type="email" className="form-control mt-2 h-[48px] p-[8px] bg-[#FAFAFA] border border-gray-300 outline-none focus:outline-none focus:ring-2 focus:ring-black" placeholder='Eg. johndoe23@gmail.com' required
                 value={user_email} 
                 onChange={(e) => setuser_email(e.target.value)}/>

              </div>
              <div className="mb-3 mt-3">
                <label className="form-label font-[400] text-[12px] leading-[16px] text-[#32324D]">Your Nationality</label>
                {/* <input type="text" className="form-control mt-2 h-[48px] p-[8px] bg-[#FAFAFA] border border-gray-300 outline-none focus:outline-none focus:ring-2 focus:ring-black"  
                 value={user_nationality} 
                 onChange={(e) => setuser_nationality(e.target.value)}/> */}
                   <div>
                                                {/* <label>Select a country:</label> */}
                                                <select value={user_nationality} onChange={handleChange} className='className="form-control mt-2 h-[48px] p-[8px] w-[100%] rounded-[8px] bg-[#FAFAFA] border border-gray-300 outline-none focus:outline-none focus:ring-2 focus:ring-black ' required>
                                                    <option value="">Choose your  country</option>
                                                    {countries?.map((country, index) => (
                                                        <option key={index} value={country}>{country}</option>
                                                    ))}
                                                </select>
                                            </div>
              </div>
              <div className="mb-3 mt-3">
                <label className="form-label font-[400] text-[12px] leading-[16px] text-[#32324D]">Add Details (if any)</label>
                <textarea className=" h-[106px] w-[100%] form-control mt-2 p-[8px] bg-[#FAFAFA] border border-gray-300 outline-none focus:outline-none focus:ring-2 focus:ring-black resize-none" rows={4} cols={50}  
                 value={description} 
                 onChange={(e) => setdescription(e.target.value)}/>

              </div>
            
              <div className='relative'>
                <button type="submit" className="btn bg-[#DE6A29] text-[#FFFFFF] 
                                   align-center font-[600] text-[14px] h-[49px] w-[133px] mt-3 
                                   absolute right-0 hover:bg-[#DE6A29] hover:text-[#FFFFFF]" id='give-a-callback' >Give a Callback</button>
              </div>
            </form>


          </div>
        </Modal>

                {/* enquire alert  */}
                <Modal onCancel={()=>{setalert(false)}}
        footer={null}
        visible={alert}
        width={'544px'}
        >
          <div className='h-[auto]  max-sm:h-[auto]' style={{fontFamily:'Montserrat'}}>
            <div className='flex justify-center items-center flex-col mt-4 mb-3 '>
              <img  src={Success} alt=''/>
              <h1 className='text-[18px] font-[600] mt-3'>Callback Confirmed</h1>
              <h6 className='font-[500] text-[14px] text-[#858585] mt-3 text-center leading-[25px]'>Thank you for reaching out to us with your request to customize package. We have received your request and are eager to assist you in tailoring the package to meet your specific needs.</h6>
              <button className=' text-[16px] font-[600] text-[white] px-5 py-2 rounded-[4px] bg-[#F77B5D] mt-3' id='alert-ok' onClick={()=>{ setalert(false); navigate("/");}}>Ok, got it</button>
            </div>
          </div>

        </Modal>
        <Whatsapp/>
      </div>

      <Footer/>
      
</>}
    </div>
  )
}

export default Packages
