// //production
const Access = "https://";
const Host = "trippersmaldives.com";
const Env = "/api";

//release
// const Access = "https://";
// const Host = "gway.release.inaipi.ae";
// const Env = "/maldivesapi";

//local
// const Access = "http://";
// const Host = "localhost:5030";
// const Env = "";

//packages
//   list all packages 
export const viewAllPackages = Access + Host + Env + "/package/ListPackage" ;
// booking package
export const BookingPackage = Access + Host + Env + "/booking/createBooking" ;
//single view package
export const singleViewPackage = Access + Host + Env + "/package/packageDetails" ;

//Activity
//   list all activity
export const viewAllActivity = Access + Host + Env + "/activity/listActivity" ;
// booking activity
export const BookingActivity = Access + Host + Env + "/booking/createBooking" ;
//single view activity
export const singleViewActivity = Access + Host + Env + "/activity/activityDetails" ;

//enquiry booking
export const BookingEnquiry = Access + Host + Env + "/enquiry/bookEnquiry" ;
//listing carousel
export const CarouselView = Access + Host + Env + "/carousel/listCarousel" ;

//blog
//listblog
export const BlogAllView = Access + Host + Env + "/blog/listBlog" ;
//single blog view
export const BlogSingleView = Access + Host + Env + "/blog/singleBlogView" ;



