import React from 'react';
import Hamburger from "../components/Hamburger_Nav.js"
import Footer from '../components/Footer';
import OffersBar from "../components/Offers_Nav";

import OffersCards from "../components/Offers_and_Deals";
import {viewAllPackages, viewAllActivity} from '../Utilis/BaseUrl.js'
import axios from 'axios';
import { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from './Images/loaderLogo.png'
import Whatsapp from '../components/Whatsapp.js';
import slugify from 'slugify';
import skeletonImage from './Images/sheleton loader image.png'
import NoOffer from './Images/no offer.jpg' 



function Offers() {

  const [getallPackages,setallAPackage] = useState([])
  const [loaderLoad , setloaderLoad] = useState(true)

  const ListAllPackage = async ()=>{
    try{

        const response = await axios.get(`${viewAllPackages}`);
        if(response){
            setallAPackage(response.data.data);                               
        }
    }catch(error){
        // console.log(error)
        navigate('/404')
    }
  }
  
  const [getActivities ,setActivities] = useState([])
  const ListAllActivities = async ()=>{
    try{
        const response= await axios.get(`${viewAllActivity}`);
        if(response){
            setActivities(response.data.data);
            setloaderLoad(false)

        }
    }catch(error){
      //   console.log(error)
      navigate('/404')
    }
  }

  //view specific packages 
  const navigate = useNavigate();
  const handlepackage =(id , package_name)=>{
    sessionStorage.setItem('Package_id',id);
    const slug = slugify(package_name)
    navigate (`/packagebooking/${slug}/${id}`)

  }
  
  const handleActivities = (id , activity_name)=>{
    sessionStorage.setItem('Activity_id',id);
    const slug = slugify(activity_name)
    navigate(`/activitybooking/${slug}/${id}`)
  }

  useEffect(()=>{
    ListAllPackage();
    ListAllActivities();
  },[]);


  //package image loader
const [packageImageLoad, setpackageImageLoad] = useState({});
           
const handlepackageimage = (id) => {
  setpackageImageLoad((prevStates) => ({
     ...prevStates,
    [id]: false,
  }));
}; 

  const [ActivityIamgeLoad, setActivityIamgeLoad] = useState({});     
  const handleactivityimage = (id) => {
  setActivityIamgeLoad((prevStates) => ({
     ...prevStates,
     [id]: false,
  }));
 };

    //filtering offer
    const packageOfferFilter = getallPackages.filter((offerFilter)=>
      offerFilter.package_offer >'0' && offerFilter.package_offer !='' && offerFilter.is_active == true 

    );
    // console.log("offeer",packageOfferFilter)

  const ActivityOfferFilter = getActivities.filter((getActivities) =>
    getActivities.activity_offer > '0' && getActivities.activity_offer !=''  && getActivities.is_active == true 

      ); 

  //  if(packageOfferFilter.length === 0 && ActivityOfferFilter.length === 0 ) {
  //   return(
  //     <div className="container flex justify-center items-center">
  //       <h1>No offer available</h1>
  //     </div>
  //   )
  //  }

  return (
    <div>

      {/* loader------ */}
   {loaderLoad === true ? 
    <div className='flex items-center justify-center flex-col  h-[100vh]   '>
      <img className='h-[60px]' src={Logo} alt='' />
      <div className="dots mt-3"></div>
     </div>
     :
     <>

        <OffersBar/>
        <Hamburger/>
        <div className='section relative'>
          <>
          {packageOfferFilter.length == 0 && ActivityOfferFilter.length == 0 ? 
          <div className="container flex justify-center items-center min-h-[50vh] ">
           <img src={NoOffer} alt='no offer' />
          </div>
           : <>
        <div className='section-2-container  mt-[-20px] max-sm:mt-[-40px] mb-[80px]'>
          {/* <OffersCards/> */}
          {packageOfferFilter.length > 0 &&
          <div className="  mx-auto max-w-[1184px] h-auto mb-[40px]  
           max-xl:w-[980px] 
           max-lg:w-[700px] 
          max-md:w-[550px]  
            max-sm:w-[100%] max-sm:px-[16px] ">
          <div className=" flex flex-row mt-4 gap-[2%] max-w-full h-auto">
     
            <div className="w-[100%]
            h-full ">
              <div className='text-2xl font-bold leading-27 text-left
                             max-md:text-[20px] 
                             max-sm:text-[18px] '>Exclusive Packages</div>
              <div id="main" className="grid gap-6 justify-evenly mt-6 grid-cols-4
                                         max-xl:grid-cols-4  
                                         max-lg:grid-cols-3  
                                         max-md:grid-cols-2    
                                         max-sm:grid-cols-1   " >

              {getallPackages?.map((l)=> (
               <>
                {l?.is_active === true  && l.package_offer > '0' &&
                  <div className="activity-card cursor-pointer relative " key={l.id} onClick={()=>handlepackage(l.id , l.package_name)}>
                     <div className="w-[50px] h-[30px] bg-orange-500 transform -rotate-45 flex items-center justify-center text-white text-[16px] font-[700] absolute top-[13px] left-[5px]">
                      {l.package_offer}% </div>
                      {packageImageLoad[l.id] !== false  && ( <img className="activity-card-img rounded-lg object-cover  animate-pulse" src={skeletonImage} alt='' /> )} 
                      <img className={`activity-card-img rounded-lg object-cover ${l.public_enabled === true ?'grayscale-0':'grayscale'}`} src={l.package_image[0]} alt='' onLoad={() => handlepackageimage(l.id)}
                    style={{ display: packageImageLoad[l.id] !== false ? 'none' : 'block' }}   /> 
                    <div className='activity-card-name text-[15px] leading-[27px] mt-2  h-[55px]  line-clamp-2
                                    max-lg:text-[15px] max-lg:leading-[25px] max-lg:h-[45px] 
                                     max-sm:h-[27px]  max-sm:line-clamp-1'>{l.package_name}
                    </div>
                    <div className=' section-2-row-2-card-1-days mt-2   rounded-3xl  py-[10px] text-white font-normal text-sm '>
                    <h6 className='bg-[#000000] px-[10px] rounded-[24px] py-1 
                                     max-lg:mt-3 max-lg:mb-3 max-lg:text-[10px]
                                     max-sm:mt-1 max-sm:mb-1'>{l?.package_duration_day} days &nbsp;{l?.package_duration_night} night</h6>
                     </div>
                     <div className='activity-card-rate text-[16px] leading-[34px]
                                     max-lg:mt-2  max-lg:text-[13px]
                                     max-sm:mt-1'>{l.package_offer===''?<span>$ {l.package_price}</span>:<>
                                     <span className='font-[400]'><del>$ {l.package_price}</del>&nbsp;&nbsp;<strong>$ {l.package_discount_price}</strong> </span>
                                     <div className={`w-[50px] h-[30px] ${l.public_enabled === true ? 'bg-[#f97316]' : 'bg-[#3490dc] grayscale'} transform -rotate-45 flex items-center justify-center text-white text-[16px] font-[700] absolute top-[13px] left-[5px]`}>
                                     {l.package_offer}% </div>
                                      </>
                                     }<span className='activity-card-span text-[14px] max:lg-text-[12px] '> per person</span></div>
                  </div>

                  }
                    </>
                 ))}


                 
              </div>
            </div>
          </div>
        </div>}
        {ActivityOfferFilter.length > 0 &&
        <div className=' mx-auto h-auto max-w-[1184px] mb-[100px] 
                                        max-xl:w-[980px] 
                                       max-lg:w-[700px] 
                                        max-md:w-[550px]  
                                       max-sm:w-[100%] max-sm:px-[16px] max-sm:mt-[-30px] '>
                            <div className='font-bold leading-66.88 text-2xl
                                              max-md:text-[20px] 
                                              max-sm:text-[18px]'>Exclusive Activities </div>
                            <div id="main" class="grid gap-6 justify-evenly mt-6 grid-cols-4
                                         max-xl:grid-cols-4  
                                         max-lg:grid-cols-3  
                                         max-md:grid-cols-2    
                                         max-sm:grid-cols-1 ">
                              {getActivities?.map((a)=>(    
                                  <>
                                {a.activity_offer > '0'  && a?.is_active === true &&                   
                                    <div className="activity-card cursor-pointer relative" key={a.id} onClick={()=>handleActivities(a.id , a.activity_name)}>
                                        <div className="w-[50px] h-[30px] bg-orange-500 transform -rotate-45 flex items-center justify-center text-white text-[16px] font-[700] absolute top-[13px] left-[5px]">
                                        {a.activity_offer}%</div>
                                        {ActivityIamgeLoad[a.id] !== false  && (<img className="activity-card-img rounded-lg object-cover  animate-pulse" src={skeletonImage} alt='' />)}                                     
                                        <img className={`activity-card-img rounded-lg object-cover  ${a.public_enabled === true ?'grayscale-0':'grayscale'} `}src={a.activity_image[0]} onLoad={() => handleactivityimage(a.id)} style={{ display: ActivityIamgeLoad[a.id] !== false ? 'none' : 'block' }}  />
                                        <div className='activity-card-name text-[15px] leading-[27px] mt-2 h-[28px] line-clamp-1
                                                        max-lg:text-[15px] max-lg:leading-[25px] max-lg:h-[26px] 
                                                        max-md:mb-[-10px]
                                                        max-sm:h-[27px]  max-sm:line-clamp-1  max-sm:mb-[1px]'>{a.activity_name}</div>
                                          {a.activity_duration === '1'?
                                        <div className='mt-2 activity-card-HRS 
                                                        max-lg:mt-1 max-lg:text-[10px]
                                                        max-sm:mt-3 '>{a.activity_duration} Hour</div>
                                        :  <div className='mt-2 activity-card-HRS 
                                        max-lg:mt-1 max-lg:text-[10px]
                                        max-sm:mt-3 '>{a.activity_duration} Hours</div>}
                                        <div className='activity-card-rate text-[16px] leading-[34px] 
                                                        max-lg:mt-0 max-lg:text-[13px]
                                                        max-sm:mt-[0px] '>
                                                           {a?.location &&
                                                          <>
                                                          {a?.activity_offer <= 0 ? (
                                                          <span>${a?.location.reduce((min, loc) => {
                                                              const locOffer = parseFloat(loc.loc_price);
                                                              return locOffer < min ? locOffer : min;
                                                          }, parseFloat(a?.location[0]?.loc_price))}</span>
                                                      ) : (
                                                          <>
                                                          <span className='font-[400]'>
                                                              <del>${a?.location[0]?.loc_price}</del>&nbsp;&nbsp;
                                                              <strong>
                                                              ${a?.location.reduce((min, loc) => {
                                                                  const locOffer = parseFloat(loc.loc_offer);
                                                                  return locOffer < min ? locOffer : min;
                                                              }, parseFloat(a?.location[0]?.loc_offer))}
                                                              </strong>
                                                          </span>
      
                                                          <div
                                                              className={`w-[50px] h-[30px] ${
                                                              a.public_enabled === true ? 'bg-[#f97316]' : 'bg-[#3490dc] grayscale'
                                                              } transform -rotate-45 flex items-center justify-center text-white text-[16px] font-[700] absolute top-[13px] left-[5px]`}
                                                          >
                                                              {a.activity_offer}%
                                                          </div>
                                                          </>
                                                      )} 
                                                      </> }
                                                      <span className='activity-card-span text-[14px] max:lg-text-[12px]'> per person</span></div>
                                    </div>
                                    }
                                    </>
                                ))}
                            </div> 
                        </div>}
        </div>
        <Whatsapp/>
           </> }
        </>
        </div>
        <Whatsapp/>
        <Footer/>
        </>}
    </div>
  )
}

export default Offers